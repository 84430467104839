export const SOCIAL_LINKS = [
  {
    id: 'twitter',
    url: 'https://twitter.com/',
  },
  {
    id: 'discord',
    url: 'https://discord.com/',
  },
]
